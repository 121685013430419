import React, { Component } from 'react';

import 'assets/scss/main.scss';
import Header from './simpleHeader';
import Menu from 'components/menu';

import Policy from 'components/policy';

import { loggedUser } from 'components/lib/auth';
class Layout extends Component {

  _mounted;
  timeoutId;

  constructor(props) {
    super(props);

    this.state = {
      isMenuVisible: false,
      loading: 'is-loading'
    };

    this.setUser = this.setUser.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
  }

  safeState(...args) {
    if (this._mounted) {
      this.setState(...args);
    }
  }

  componentDidMount() {
    this._mounted = true;

    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' });
    }, 100);

    loggedUser()
      .then(this.setUser)
      .catch(() =>
        this.setUser({
          data: null
        })
      );
  }

  componentWillUnmount() {
    this._mounted = false;

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  setUser({ data: user }) {
    this.safeState({ user });
  }

  handleToggleMenu(e) {
    this.safeState({
      isMenuVisible: !this.state.isMenuVisible
    });

    if (e.currentTarget) {
      const href = e.currentTarget
        .getAttribute('href');

      if (href && !(/^\//).test(href)) {
        e.preventDefault();
      }
    }
  }

  render() {
    const { children } = this.props;
    const {
      user,
      loading,
      isMenuVisible
    } = this.state;

    return (
      <div className={`body
                ${loading ? ' ' + loading : ''}
                ${isMenuVisible ? ' is-menu-visible' : ''}
            `}>
        <div id="wrapper">
          <Header
            user={user}
            className="alt"
            onToggleMenu={this.handleToggleMenu} />
          {children}
        </div>

        <Menu
          user={user}
          onToggleMenu={this.handleToggleMenu} />

        <Policy />
      </div>
    )
  }
}

export default Layout
