import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next'
import Layout from 'components/layout/simple';
import background from 'assets/images/banner-img.png';



const styles = () => ({
    backgroundImage: `url(${background})`
});


const ExpiredPage = () => {
	const { t } = useTranslation()
	return (
    <Layout>
      <Helmet title={t('pages.helmetTitle.session')} />

      <section id="banner">
        <div className="splash" style={styles()} />
        <div className="inner">
          <div>
            <header className="major">
              <h1>{t('pages.helmetTitle.session')}</h1>
            </header>
            <div className="content">
              <p>{t('common.errorMessage.authenticationError')}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="section section--gradient authentication">
        <div className="inner content">
          <p className="error w-100">{t('pages.helmetTitle.session')}</p>
        </div>
      </section>
    </Layout>
  )
}

export default ExpiredPage;