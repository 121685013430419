/* eslint-disable global-require */
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby';
import { authUrl } from 'components/lib/auth';
import { useTranslation } from 'react-i18next';
import LanguageMenu from '../header/languageMenu';
import config from 'components/config';
import i18n from 'localization';
import './header.scss';


const userName = (user) => {
  if (user?.userType === 'fintech-developer') {
    return 'Developer';
  } else if (user?.userType === 'fintech-admin') {
    return 'Admin';
  } else {
    return 'OBAF';
  }
};
export default ({
  user,
  className
}) => {
  const { t } = useTranslation();
  const [dropDownOn, setDropDownOn] = useState(false);
  const [state, setState] = useState(() => authUrl());
  const dropDownRef = useRef();

  useEffect(() => {
    document.addEventListener('click', handleClick);

    function handleClick(e) {
      if (dropDownRef && dropDownRef.current) {
        const ref = dropDownRef.current;

        if (!ref.contains(e.target)) {
          setDropDownOn(false);
        }
      }
    }

    const url = window.location.href?.trim?.();
    const domain = url?.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)

    setState(authUrl(domain[0]));

    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <>
      <header id="header" className={className || null} dir={i18n?.language === 'ar' ? 'rtl' : 'ltr'}>
        <div className="content">
          <Link to="/" className="logo">
            {config.logoUrl ? (
              <>
                <Helmet link={[{ rel: 'icon', type: 'image/svg', href: config.logoUrl }]}> </Helmet>
                <figure className="header-logo-wrapper">
                  <img
                    // src={require(`../../assets/images/tenant/${isSubdomain?.toLowerCase()}.svg`)}
                    src={config.logoUrl}
                    alt={config.companyName}
                  />
                </figure>
              </>
            ) : (
              <div className="header-logo-text">
                <p>{userName(user)}</p>
                <div className="header-logo-text-dot" />
              </div>
            )}
          </Link>
            <div className="header-login-wrapper">
              <span className="login">{t('pages.contact.alreadyHaveAccount')}?{' '}</span>
              <button>
                <a href={state}>{t('common.signIn')}</a>
              </button>
              <LanguageMenu />

            </div>
          </div>
      </header>
    </>
  )
}